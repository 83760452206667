import React, { useState } from "react";

// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useAuthState } from "../../context";
import styles from "../login.module.css";

// get special state if we're coming from "confirm" page
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useOutletContext } from "react-router-dom";

// As an "outside" page
import PublicHeader from "./header";

function OutsideLoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  // Grab current all projects (and set) from Outlet (in root.js)
  const [
    activeProject,
    setActiveProject,
    allProjects,
    setAllProjects,
    weakUsername,
    setWeakUsername,
  ] = useOutletContext();

  const { loading, errorMessage } = useAuthState();

  const { state } = useLocation(); // info could come from /confirm

  let emailStarter;
  let emailLock;
  try {
    emailStarter = state.username;
    console.log("state.username is: " + state.username);
    if(emailStarter){
      emailLock = true;
    }
    else {
      emailLock = false;
    }
  } catch {
    emailStarter = email;
    emailLock = false;
  }
  try {
    // if it came from /join the username will come in differently
    if (state.newUserUsername) {
      console.log("state.newUserUsername is: " + state.newUserUsername);
      emailStarter = state.newUserUsername;
    }
  } catch {
    console.log("No newUserUsername");
  }

  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Use location-state email if provided, otherwise take the page state (from input)
      let loginEmail = emailLock ? emailStarter : email;

      //let response = await loginUser(dispatch, { loginEmail, password });

      const loginUserInfo = { username: loginEmail, password: password };

      const response = await fetch("/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginUserInfo),
      });
      const loginResponse = await response.json();

      if (loginResponse.username) {
        setWeakUsername(loginResponse.username);

        // Download all saved gears (and projects) from the database and store into localStorage

        var dbEntry = {
          username: loginResponse.username,
          idtoken: loginResponse.idToken,
          refreshtoken: loginResponse.refreshToken,
        };
        var responseDB = await fetch("/loadfromdb", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dbEntry),
        });
        const apiResponse = await responseDB.json();

        var downloadedGears = apiResponse.dbresponse.code; // This should be the array of all individual gears

        // update for new userdata storage! all in one big array
        var allUserGears = [];
        for (let i = 0; i < downloadedGears.length; i++) {
          // Each individual gear record
          // Record name
          var recordPrefix;
          if (downloadedGears[i].recordtype === "single") recordPrefix = "gear";

          // Stringify/process the DB->localStorage handling

          downloadedGears[i].inputstring = JSON.parse(
            downloadedGears[i].inputstring
          ); // This was stringified twice

          //localStorage.setItem(recordPrefix + '-' + downloadedGears[i].gear, JSON.stringify(downloadedGears[i]));
          allUserGears.push(JSON.stringify(downloadedGears[i])); // Add gear to list
          //if(!userProjects.includes(downloadedGears[i].project)) userProjects.push(downloadedGears[i].project);
        }
        localStorage.setItem("userdata", "[" + allUserGears + "]"); // Force brackets to make it into an array

        // Download and restore projects

        var dbEntryProject = {
          username: loginResponse.username,
          idtoken: loginResponse.idToken,
          refreshtoken: loginResponse.refreshToken,
        };

        var responseDBProject = await fetch("/loadprojects", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dbEntryProject),
        });
        const apiResponseProject = await responseDBProject.json();

        var downloadedProjects = apiResponseProject.dbresponse.code; // This should be the array of all individual projects

        const defaultProject = {
          projectid: "default-" + loginResponse.username,
          projectname: "default",
          username: loginResponse.username,
          creationtime: "1000000000000",
        };

        var userProjects = [defaultProject];
        for (let i = 0; i < downloadedProjects.length; i++) {
          userProjects.push(downloadedProjects[i]);
        }
        setAllProjects({ projects: userProjects }); // Set state of all projects
        setActiveProject({ project: "default-" + loginResponse.username });

        setMessage("Thanks for logging in! Redirecting now...");
        localStorage.setItem(
          "projects",
          JSON.stringify({ projects: userProjects })
        );
        localStorage.setItem("currentUser", JSON.stringify(loginResponse));

        // TODO: delay and then redirect
        setTimeout(function () {
          navigate("/home"); // user has been set, now bring them back to the homepage
        }, 2000);
      } else {
        setMessage("Login was not successful. Error: " + loginResponse.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader noHeaderImg />

          <Row>
            <Card className="project-card">
              <div className="project-name">
                <h2
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  Login
                </h2>
              </div>

              <div style={{ width: "400px" }}>
                {errorMessage ? <p>Error, please try again</p> : null}
                <form>
                  <div className={styles.loginForm}>
                    <div className={styles.loginFormItem}>
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        id="email"
                        value={emailStarter}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading || emailLock}
                      />
                    </div>
                    {emailLock ? (
                      <Link
                        to="/login"
                        style={{ marginTop: -10 + "px" }}
                        className="d-inline-flex text-decoration-none rounded"
                      >
                        Login as someone else
                      </Link>
                    ) : (
                      <></>
                    )}
                    <div className={styles.loginFormItem}>
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleLogin}
                    disabled={loading}
                    className="btn-primary"
                    style={{ marginTop: "20px" }}
                  >
                    Login
                  </button>
                  <div style={{ fontSize: ".9em", marginTop: 10 + "px" }}>
                    <a className="announcement-link" href="/forgotpassword">
                      Forgot password?
                    </a>
                  </div>
                  <div className="loading-message">
                    {message ? message : ""}
                  </div>
                </form>
              </div>

              <hr style={{ margin: "40px 0" }}></hr>

              <Row>
                <Col sm={12}>
                  <h3 style={{ marginBottom: "20px" }}>Sign Up</h3>
                  <p>
                    Click{" "}
                    <a className="announcement-link" href="/join">
                      here
                    </a>{" "}
                    to register and make an account
                  </p>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}

export default OutsideLoginPage;
