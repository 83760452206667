import { Card, Container } from "react-bootstrap";
import { useOutletContext, Link } from "react-router-dom";
import React from "react";

export default function ProjectsPage({ userLevel, pageLevel }) {
  const [project, setProject, allProjects, setAllProjects] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [uploadMessage, setUploadMessage] = React.useState('')
  const [showUploadMessage, setShowUploadMessage] = React.useState(false);
  const [createMessage, setCreateMessage] = React.useState('')
  const [showCreateMessage, setShowCreateMessage] = React.useState(false);
  
  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  // Pull in all of localStorage (but only once!)
  let allSavedGears = []; // resultant gears for this calculator
  var allSavedMachines = []; // machines (list of change gears) are similar to gears

  var tempUserInfo, tempUsername, idToken, refreshToken;
  try {
    // Check if there is an existing record, and if so, pull in its data
    tempUserInfo = localStorage.getItem("currentUser");
    tempUserInfo = JSON.parse(tempUserInfo);
    tempUsername = tempUserInfo.username;
    idToken = tempUserInfo.idToken;
    refreshToken = tempUserInfo.refreshToken;
  } catch {
    tempUsername = "suspectuser";
    idToken = "";
    refreshToken = "";
  }

  
  var userData; // Protect in case the record doesn't exist
  try {
    userData = localStorage.getItem("userdata"); // get userdata from localStorage [should be an array of objects]
    userData = JSON.parse(userData); // Parse here (so the userdata becomes an array of objects, not a string)
  } catch {
    userData = [];
  }
  if (!userData) userData = [];

  userData.forEach((element, index) => {
    try {
      if (element["recordtype"] === "single") {
        // gear is acceptable for this calculator

        var gearToAdd = [
          index,
          element["gear"],
          element["project"],
          element["creationtime"],
          element["updatetime"],
          element["inputstring"],
          element["gearid"],
          "single",
        ];
        allSavedGears.push(gearToAdd);
      }
      if (element["recordtype"] === "machine") {
        // machine is acceptable for this calculator

        var machineToAdd = [
          index,
          element["gear"],
          element["project"],
          element["creationtime"],
          element["updatetime"],
          element["inputstring"],
          element["gearid"],
          "machine",
        ];
        allSavedMachines.push(machineToAdd);
      }
    } catch {
      // couldn't parse
      console.log("Failed to parse saved gear:", element);
    }
  });

  // TODO: Turn this into a table once the allProjects record has data
  // Structure of project: name, date created, date modified, (calculate) # of gears

  // TODO: Clean this up
  var safeActive, safeAllProjects;
  try {
    safeActive = project.project;
  } catch {
    safeActive = "not defined";
  }
  try {
    safeAllProjects = allProjects.projects;

    // Unassigned gears are shown in a separate category. Default needs to be filtered out so they aren't repeated

    safeAllProjects = safeAllProjects.filter(
      (eachProject) => !eachProject.projectid.toString().startsWith("default")
    ); // Filter out default
  } catch {
    safeAllProjects = ["not yet defined"];
  }
  //const safeActive = project.hasOwnProperty('project') ? project.project : {project:"not defined"};
  //const safeAllProjects = allProjects.hasOwnProperty('projects') ? allProjects.projects : ["not defined"];

  const cyrb53 = function (str, seed = 789432) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };
  // TODO: Hash based off of userName, projectName, and creation time.
  // TODO: need to safely bring username into the save function

  function createProject(event) {
    event.preventDefault();
    let projectName = document.getElementById("input-projectname").value;
    //TODO: Get username!!
    // TODO / TODO_SECURE: get username securely
    var tempUsername = JSON.parse(localStorage.getItem("currentUser")).username;

    setCreateMessage(''); // clear previous messages
    setShowCreateMessage(false); // clear previous messages

    let projectCreationTime = Date.now();

    var saveProjectID = cyrb53(projectName + projectCreationTime.toString());

    // Loop through all projects to find out if it's new
    var isNewProject = true;
    for (let i = 0; i < safeAllProjects.length; i++) {
      if (
        safeAllProjects[i].projectname === projectName ||
        safeAllProjects[i].projectid === saveProjectID
      ) {
        isNewProject = false;
        setCreateMessage('Error: Project already exists');
        setShowCreateMessage(true);
        break;
      }
    }

    if(!projectName){
      isNewProject = false;
      setCreateMessage('Error: Enter a project name');
      setShowCreateMessage(true);
    }

    if (isNewProject) {
      const newProject = {
        projectid: saveProjectID,
        projectname: projectName,
        username: tempUsername,
        creationtime: projectCreationTime,
      };
      safeAllProjects.push(newProject);
      createProjectsDB(newProject); // Add project to Database!
    }
    //if(!safeAllProjects.includes(projectName)) safeAllProjects.push(projectName); // Only add if it's a new project name

    let newAll = { projects: safeAllProjects };

    setAllProjects(newAll);
  }

  function uploadProject(event) {
    event.preventDefault();
    let projectName = document.getElementById("input-projectnameupload").value;
    //TODO: Get username!!
    // TODO / TODO_SECURE: get username securely
    var tempUsername = JSON.parse(localStorage.getItem("currentUser")).username;

    setUploadMessage(''); // clear previous messages
    setShowUploadMessage(false); // clear previous messages

    let projectCreationTime = Date.now();

    var saveProjectID = cyrb53(projectName + projectCreationTime.toString());

    // Loop through all projects to find out if it's new
    var isNewProject = true;
    for (let i = 0; i < safeAllProjects.length; i++) {
      if (
        safeAllProjects[i].projectname === projectName ||
        safeAllProjects[i].projectid === saveProjectID
      ) {
        isNewProject = false;
        // stop. this project already exists
        setUploadMessage('Error: A project already exists with that name');
        setShowUploadMessage(true);
        break;
      }
    }
    if(!projectName){
      isNewProject = false;
      setUploadMessage('Error: Enter a project name');
      setShowUploadMessage(true);
    }
    if (isNewProject) {
        const file = document.getElementById('fileInput').files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                processCSV(content, saveProjectID);
            };
            reader.readAsText(file);
        }
        else {
          setUploadMessage('Error: Select a file');
          setShowUploadMessage(true);
          return; // do not create a project if it does not have a name
        }

      const newProject = {
        projectid: saveProjectID,
        projectname: projectName,
        username: tempUsername,
        creationtime: projectCreationTime,
      };
      safeAllProjects.push(newProject);
      createProjectsDB(newProject); // Add project to Database!

      // add all the gears+machines from the project


    }
    //if(!safeAllProjects.includes(projectName)) safeAllProjects.push(projectName); // Only add if it's a new project name

    let newAll = { projects: safeAllProjects };

    setAllProjects(newAll);
  }

  function processCSV(text, projectID) {
    // Split the text into an array of lines


    let lineSplitRegex = /\r?\n/;
    const lines = text.split(lineSplitRegex);
    // console.log("all lines");
    // console.log(lines);
    lines.shift(); // delete the top line

    //return lines;
    addAllLines(lines, projectID);
    setUploadMessage('Uploaded');
    setShowUploadMessage(true);
    setProject(projectID); // trigger a refresh after all the project gears have been added
  }
  async function addAllLines(lines, projectID) {
    let projectName = document.getElementById("input-projectnameupload").value;
    var currentTime = Date.now();
    let gearIdIndex = 0;
    //lines.forEach(line => {
    for await (const line of lines) {
      gearIdIndex++;
      // console.log("new line");
      // console.log(line);
      let columnSplitRegex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
      const columns = line.split(columnSplitRegex);
      let saveGearId = cyrb53(projectID + currentTime.toString() + gearIdIndex)
      // Handle the columns array
      // console.log("columns");
      // console.log(columns);

      // Decide whether to continue processing this entry
      if(!columns[6] || !columns[4] || !columns[0]){
        // SKIP
        continue;
      }

      // divide the comma-separated string into an object for "inputstring"
      // console.log("Need to JSON: ");
      // console.log(columns[4]);
      let inputStringObject = {};
      let inputStringPairs = columns[4].replace(/"/g,'').split(/,(?=[A-Za-z])/g);
      // Strip extra quotation marks around the 'input string'
      // Split the resultant at commas that are followed by a letter
      // Splitting at commas allows numteeth:10,pangle:20, etc to break correctly
      // Requiring the following letter fixes it for change gears: cgears:10,11,12,13,cgearsfeed:14,15, etc
      // Following letter needs to be in a positive lookahead so it is not consumed by the split()

      for(let inputPair of inputStringPairs){
        let inputValues = inputPair.split(":");
        console.log(inputValues[0] + ":" + inputValues[1]);
        inputStringObject[inputValues[0]] = inputValues[1];
      }
      console.log(inputStringObject);
      var infoToSave = {
        gearid: saveGearId,
        username: tempUsername, // username will be verified by server check against idToken
        idtoken: idToken,
        refreshtoken: refreshToken,
        project: projectID,
        gear: columns[0],
        recordtype: columns[6],
        //calcversion: saveCalcVersion,
        inputstring: inputStringObject,//columns[4],
        creationtime: currentTime,
        updatetime: currentTime,
      };
      userData.push(infoToSave); // Add the gear to localStorage
      // for each gear, now need to add to database
      const writeDB = async (dbEntry) => {
          // e.preventDefault();
          console.log("writting to database");
          console.log(dbEntry);
          try {
            const response = await fetch("/savetodb", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(dbEntry),
            });
            const apiResponse = await response.json();
      
            // Update localStorage with a refreshed IdToken (if provided)
            if (apiResponse.newIdToken) {
              // We've received a refreshed token from our call!
              // Update saved Token
              console.log("new token:" + apiResponse.newIdToken);
              tempUserInfo["idToken"] = apiResponse.newIdToken;
              localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
            }
          } catch (error) {
            console.log(error);
          }
        };
      
        // Only update record if user is paid! (level >0)
      
        var userlevel = 100;//await getUserLevel(tempUserInfo);
      
        if (true || userlevel.usermaxlevel > 0) {

          // // add (as necessary) to the localStorage
          // var gearIsNew = true;
          // if (!userData) userData = []; // set to an empty array if falsy
          // userData.forEach((element, index) => {
          //   if (element.gearid == columns[5]) {
          //     // gear ID could be stored as String or Int!
          //     gearIsNew = false; // This is a replacement, not a near gear
          //     userData[index] = infoToSave; // Do the replacement!
          //   }
          // });
          // if (gearIsNew) {
          //   userData.push(infoToSave);
          // }
          localStorage.setItem("userdata", JSON.stringify(userData)); // Update the saved record
      
          // Save this information to the Database!
          writeDB(infoToSave);
        }

    };
  }


  function downloadProject(event) {
    event.preventDefault();

    let projectIDtoDownload = event.target.value;

    // Download all gears within the project
    var projectGearsToDownload = [];
    for (let i = 0; i < allSavedGears.length; i++) {
      if (allSavedGears[i][2] === projectIDtoDownload) {
        projectGearsToDownload.push(allSavedGears[i]); // Array of Gears
      }
    }

    // Download machines within the project as well
    for (let i = 0; i < allSavedMachines.length; i++) {
      if (allSavedMachines[i][2] === projectIDtoDownload) {
        projectGearsToDownload.push(allSavedMachines[i]); // Array of Gears
      }
    }

    // get the project name
    let projectNameFromID;
    console.log(safeAllProjects);
    for(let i=0;i<safeAllProjects.length;i++){
      if(safeAllProjects[i].projectid == projectIDtoDownload){
        projectNameFromID = safeAllProjects[i].projectname;
        projectNameFromID = projectNameFromID.replaceAll(" ","_");
        projectNameFromID = projectNameFromID.replaceAll(/[\\/:*?"<>|.]/g,"");
        projectNameFromID += ".csv";
      }
      console.log("project name is: ", projectNameFromID);
    }

    if (projectGearsToDownload.length > 0) downloadGear(projectGearsToDownload,projectNameFromID); // if there were gears in that project, download them
  }

  function downloadGear(gears, filename="gearcalculators.csv") {
    // Take in an array of gearIDs to download

    // const gearIDasInts = gearID.map((value) => {
    //   return parseInt(value);
    // });
  
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "name,project_id,time_created,time_updated,data,id,record_type\r\n";

    let copyGears = structuredClone(gears); // deep copy so the shift doesn't affect the original (which breaks (show))

    copyGears.forEach(function (rowArray) {
      // if the entry is a dict, need to stringify that
      for (let i = 0; i < rowArray.length; i++) {
        rowArray[i] = JSON.stringify(rowArray[i])
          .replaceAll('"', "")
          .replaceAll(/[{}]/g, '"');
        //rowArray[i].replace('"','\'');
      }
      rowArray.shift(); // remove first record (arbitrary index)
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    // console.log(csvContent);

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    
    link.click();

    // var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
  }

  function deleteProject(event) {
    event.preventDefault();

    let projectIDtoDelete = event.target.value;

    // Delete all gears within the project
    var projectGearsToDelete = [];
    for (let i = 0; i < allSavedGears.length; i++) {
      if (allSavedGears[i][2] === projectIDtoDelete) {
        projectGearsToDelete.push(allSavedGears[i][6]); // Array of Gear IDs
      }
    }
    if (projectGearsToDelete.length > 0) deleteGear(projectGearsToDelete); // if there were gears in that project, delete them

    // Find the to-be-deleted project from the Array, can't use indexOf because it's looking at dictionaries (not just an array)
    let indexToDelete = -1;
    for (let i = 0; i < safeAllProjects.length; i++) {
      if (
        projectIDtoDelete &&
        safeAllProjects[i].projectid == projectIDtoDelete
      ) {
        indexToDelete = i;
      }
    }

    if (indexToDelete > -1) {
      safeAllProjects.splice(indexToDelete, 1);
    }

    let newAll = { projects: safeAllProjects };

    // Delete from database!
    deleteProjectsDB([projectIDtoDelete]);

    setAllProjects(newAll);
  }

  function toggleInputString(gearID) {
    // Show the input string for a gear
    console.log("Toggling input: " + gearID);
    document.getElementById("str-" + gearID).style.display = "inline";
    document.getElementById("btn-" + gearID).style.display = "none";
    return;
  }

  async function deleteGear(gearID, gearName = []) {
    // Take in an array of gearIDs to delete and OPTIONALLY, an array of gearNames to delete from localStorage

    const gearIDasInts = gearID.map((value) => {
      return parseInt(value);
    });

    var dbEntry = {
      username: tempUsername,
      gearids: gearID,
      idtoken: idToken,
      refreshtoken: refreshToken,
    };
    var responseDB = await fetch("/deletegear", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dbEntry),
    });
    const apiResponse = await responseDB.json();

    // Update localStorage with a refreshed IdToken (if provided)
    if (apiResponse.newIdToken) {
      // We've received a refreshed token from our call!
      // Update saved Token
      tempUserInfo["idToken"] = apiResponse.newIdToken;
      localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
    }

    // now we need to delete from  the localstorage
    // existing gear data is in userData
    userData = userData.filter((object) => {
      return !gearIDasInts.includes(parseInt(object.gearid));
    });

    localStorage.setItem("userdata", JSON.stringify(userData));

    // When deleting the entire project:
    // TODO: when deleting unassigned gears, they aren't deleted
    // TODO: this is true for project-based gears too, they still exist in localStorage (which might be o)
    // get list of names from the list of IDs THEN delete from that list
    var allSavedCombined = [...allSavedGears, ...allSavedMachines];
    for (let i = 0; i < allSavedCombined.length; i++) {
      if (gearIDasInts.includes(parseInt(allSavedCombined[i][6]))) {
        try {
          document.getElementById(
            "row-" + allSavedCombined[i][6]
          ).style.display = "none";
        } catch {}
      }
    }

    return;
  }

  async function createProjectsDB(projectObject) {
    // TODO / TODO_SECURE: get username securely

    var projectObjectHandler = {
      projectArray: projectObject,
      idtoken: idToken,
      refreshtoken: refreshToken,
      username: tempUsername,
    };

    var responseDB = await fetch("/saveproject", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(projectObjectHandler),
    });
    const apiResponse = await responseDB.json();

    // Update localStorage with a refreshed IdToken (if provided)
    if (apiResponse.newIdToken) {
      // We've received a refreshed token from our call!
      // Update saved Token
      tempUserInfo["idToken"] = apiResponse.newIdToken;
      localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
    }

    return;
  }

  async function deleteProjectsDB(projectID) {
    var dbEntry = {
      username: tempUsername,
      projectids: projectID,
      idtoken: idToken,
      refreshtoken: refreshToken,
    };
    var responseDB = await fetch("/deleteprojects", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dbEntry),
    });
    const apiResponse = await responseDB.json();

    // Update localStorage with a refreshed IdToken (if provided)
    if (apiResponse.newIdToken) {
      // We've received a refreshed token from our call!
      // Update saved Token
      tempUserInfo["idToken"] = apiResponse.newIdToken;
      localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
    }

    return;
  }

  function listGearsInProject(projectID) {
    // Get (and format?) all the gears included for a given project
    // Will use this same approach for machines

    // TODO: format!
    // TODO: '(show)' button should react on hover

    var gearsInProject = [];
    var machinesInProject = [];

    for (let i = 0; i < allSavedGears.length; i++) {
      if (allSavedGears[i][2] == projectID) {
        // Can't be a parseInt or === because project Name could be an Int or String
        gearsInProject.push(allSavedGears[i]);
      }
    }
    for (let i = 0; i < allSavedMachines.length; i++) {
      if (allSavedMachines[i][2] == projectID) {
        // Can't be a parseInt or === because project Name could be an Int or String
        machinesInProject.push(allSavedMachines[i]);
      }
    }

    gearsInProject.sort();
    machinesInProject.sort();
    var combinedInProject = [...gearsInProject, ...machinesInProject];

    return combinedInProject.length > 0 ? (
      <>
        <table>
          <thead>
            <tr key="headers">
              <th>Name</th>
              <th className="project-head-date">Created</th>
              <th className="project-head-date">Updated</th>
              <th>Parameters</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {combinedInProject.map(function (object, i) {
              return (
                <tr
                  id={"row-" + object[6]}
                  key={object[6]}
                  style={{
                    backgroundColor: object[7] === "machine" ? "#eeeeee" : "",
                  }}
                >
                  <td>{object[1]}</td>
                  <td className="project-row-date">
                    {new Date(parseInt(object[3])).toLocaleString()}
                  </td>
                  <td className="project-row-date">
                    {new Date(parseInt(object[4])).toLocaleString()}
                  </td>
                  <td className="project-row-inputs">
                    <span
                      style={{ color: "#0077ff", cursor: "pointer" }}
                      id={"btn-" + object[6]}
                      onClick={() => toggleInputString(object[6])}
                    >
                      (show) {object[6]}
                    </span>
                    <span style={{ display: "none" }} id={"str-" + object[6]}>
                      {JSON.stringify(object[5])}
                    </span>
                  </td>
                  <td>
                    <span
                      className="delete-gear"
                      id={"delbtn-" + object[6]}
                      onClick={() => deleteGear([object[6]], [object[1]])}
                    >
                      ×
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p>
        <i>No gears in this project</i>
      </p>
    );
  }

  return (
    <Container className="inside-container">
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Projects</div>
        <p style={{ marginTop: "20px" }}>
          Save gears to your account, and organize them by project.
        </p>

        {userLevel === 0 && (
          <div>
            <hr />
            <i>This feature is only available for paid subscribers.</i>
            <Link to="/change-plan" style={{ marginLeft: "20px" }}>
              Upgrade Plan
            </Link>
          </div>
        )}

        {userLevel > 0 && userLevel >= pageLevel && (
          <form>
            <hr style={{ marginBottom: "40px" }} />
            <h4>Create a new project</h4>
            <div style={{ margin: "20px 0 10px" }}>
              <input
                type="text"
                id="input-projectname"
                size="35"
                placeholder="Project Name"
              />
            </div>

            <button
              className="btn-primary"
              text="Create"
              id="button-create"
              onClick={createProject}
            >
              Create Project
            </button>
            <div id="output" hidden={!showCreateMessage} className="save-message error">{createMessage}</div>
          </form>
        )}

          {userLevel > 0 && userLevel >= pageLevel && (
          <form>
            <hr style={{ marginBottom: "40px" }} />
            <h4>Upload an existing project</h4>
            <div style={{ margin: "20px 0 10px" }}>
              <input
                type="text"
                id="input-projectnameupload"
                size="35"
                placeholder="Project Name"
              />
            </div>

            <button
              className="btn-primary"
              text="Create"
              id="button-create"
              onClick={uploadProject}
            >
              Upload Project
            </button> &nbsp;
            <input type="file" id="fileInput" accept=".csv" />
            <div id="output" hidden={!showUploadMessage} className="save-message error">{uploadMessage}</div>
          </form>
        )}
      </Card>

      {userLevel > 0 && userLevel >= pageLevel && (
        <>
          <Card className="project-card">
            <div className="project-name gray flex">
              Unassigned Gears
              <div>
                <button
                  className="inside-btn-download"
                  text="Download"
                  value={"default-" + tempUsername}
                  onClick={downloadProject}
                >
                  Download
                </button>

                <button
                  className="inside-btn-delete"
                  text="Delete"
                  value={"default-" + tempUsername}
                  onClick={deleteProject}
                >
                  Delete
                </button>
              </div>
            </div>

            {listGearsInProject("default-" + tempUsername)}
          </Card>

          {safeAllProjects.map(function (object, i) {
            return (
              <Card className="project-card" key={`project-${i}`}>
                <div className="project-name gray flex">
                  {object.projectname}
                  <div>
                    <button
                      className="inside-btn-download"
                      text="Download"
                      value={object.projectid}
                      onClick={downloadProject}
                    >
                      Download
                    </button>
                    <button
                      className="inside-btn-delete"
                      text="Delete"
                      value={object.projectid}
                      onClick={deleteProject}
                    >
                      Delete
                    </button>
                  </div>
                </div>

                {listGearsInProject(object.projectid)}
              </Card>
            );
          })}
        </>
      )}
    </Container>
  );
}
