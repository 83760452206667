export function loadInputs(event, gearRecordID) {
  event.preventDefault();
  // Get the gear record from the dropdown selection
  // NOW THIS IS AN INPUT PARAMETER FROM THE PAGE
  // var gearRecordID = document.getElementById('input-loadgearname').value;

  // On its existence, fill in the inputs accordingly and calculate
  if (gearRecordID !== "default") {
    // This is so if the user clicks Load when there is no gear, it won't try to load anything
    try {
      var recalledData = localStorage.getItem("userdata"); // change to userdata storage object
      // Grabs all userdata
      recalledData = JSON.parse(recalledData);

      recalledData = recalledData.filter((object) => {
        // Filter to just the data that matches the gear ID (dropdown now needs to base off gear ID!)
        return object.gearid == gearRecordID; // ID could be stored as string or integer!
      });

      recalledData = recalledData[0]; // Use just the first filtered data point (should just be one because of ID)
      var recallInputs = recalledData.inputstring;

      // Fill the 'Save' field with gear name
      if (
        recalledData.recordtype == "single" ||
        recalledData.recordtype == "machine"
      ) {
        document.getElementById("input-gearname").value = recalledData.gear;
      }
      if (recalledData.recordtype == "hob") {
        document.getElementById("input-hobname").value = recalledData.gear;
      }

      if (
        document.getElementsByName("units")[0] &&
        document.getElementsByName("units")[1]
      ) {
        recallInputs.unitsMM
          ? (document.getElementsByName("units")[0].checked = true)
          : (document.getElementsByName("units")[1].checked = true);
      }
      if (
        document.getElementsByName("input-type")[0] &&
        document.getElementsByName("input-type")[1]
      ) {
        recallInputs.typeExt
          ? (document.getElementsByName("input-type")[0].checked = true)
          : (document.getElementsByName("input-type")[1].checked = true);
      }
      let gearParams = [
        'numteeth',
        'module',
        'pangle',
        'helical',
        'shift',
        'pindia',
        'span',
        'face',
        'I',
        'pangdrop',
        'sacdrop',
        'materialdrop',
        'Sy',
        'Se',
        'hardness',
        'matldrop',
        'machinability',
        'basespeed',
        'scallop',
        'numteethgear',   // I/J
        'tooltipradius',  // I/J calc
        'thinning',       // I/J calc
        'overloadfactor',           // Rating
        'sizefactor',               // Rating
        'loadsharingfactor',        // Rating
        'surfacecondition',         // Rating
        'allowablecontact',         // Rating
        'hardnessfactor',           // Rating
        'safetyfactorpitting',      // Rating
        'safetyfactorbending',      // Rating
        'temperaturefactor',        // Rating
        'reliabilityfactor',        // Rating
        'rimthickness',             // Rating
        'allowablebendingstress',   // Rating
        'pinionspeed',              // Rating
        'transmittedpower',         // Rating
        'transmittedtorque',        // Rating
        'contactsperrev',           // Rating
        'accuracy',                 // Rating
        'poissonpinion',            // Rating
        'poissongear',              // Rating
        'elasticitymoduluspinion',  // Rating
        'elasticitymodulusgear',    // Rating
        'life',                     // Rating
        'planets',        // Planetary
        'ratio',          // Planetary
        'speed',          // Planetary
        'kplanetary',     // Planetary 
        'dynamicdrop',    // Dudley
        'distdrop',       // Dudley
        'powersource',    // Dudley
        'loadshock',      // Dudley
        'pmatdrop',       // Dudley
        'gmatdrop',       // Dudley
        'lifecycles',     // Dudley
        'safetydrop',     // Dudley  

      ];
      for (let i=0;i<gearParams.length;i++){
        if (recallInputs[gearParams[i]] && document.getElementById("input-" + gearParams[i]))
        document.getElementById("input-"+gearParams[i]).value = recallInputs[gearParams[i]];
      }

      if (recallInputs.precision && document.getElementById("precision"))
        document.getElementById("precision").value = recallInputs.precision;

      // Lewis Form Factor Drop Downs
      if (recallInputs.pangle && document.getElementById("input-pangdrop")) {
        if (recallInputs.pangle === 14.5 || recallInputs.pangle === 20) {
          document.getElementById("input-pangdrop").value = recallInputs.pangle;
        }
      }

      // Machine-Specific Parameters
      // TEST LIST
      let machineParams = [
        'manufacturer',
        'model',
        'mfgdate',
        'serial',
        'maxmodule',
        'minmodule',
        'maxdiaspur',
        'maxfacespur',
        'mintooth',
        'maxhobspeed',
        'minhobspeed',
        'maxfeedlong',
        'minfeedlong',
        'maxdiahob',
        'cgears',
        'cgearsfeed',
        'cgearshelical',
        'mcindex',
        'mcfeed',
        'mchelical',
      ];
      for (let i=0;i<machineParams.length;i++){
        if (recallInputs[machineParams[i]] && document.getElementById("input-" + machineParams[i]))
        document.getElementById("input-"+machineParams[i]).value = recallInputs[machineParams[i]];
      }

      // Hob Feed & Speed specific (gears)

      // HOB TYPE Elements

      let hobParams = [
        'hobdia', 
        'hobmatldrop',
        'hobcoatingdrop',
        'hobkfactor',
        'coatingkfactor',
      ];
      for (let i=0;i<hobParams.length;i++){
        if (recallInputs[hobParams[i]] && document.getElementById("input-" + hobParams[i]))
        document.getElementById("input-"+hobParams[i]).value = recallInputs[hobParams[i]];
      }
      // Hob Feed and Speed specific [hob]

      // if (
      //   recallInputs.hobcoatingdrop &&
      //   document.getElementById("hobcoatingdrop")
      // )
      //   document.getElementById("hobcoatingdrop").value =
      //     recallInputs.hobcoatingdrop;

      // Can't calculate directly from here, instead return a success

      return true;
    } catch {
      // Can't calculate (or set state) from here, instead a return a failure

      console.log("Loading inputs failed");
      return false;
    }
  }
}
