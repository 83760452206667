import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
  SaveButtonBlock,
  ScrollAnchorTop,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorPlanetary({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  // Common calculator helper functions
  var radioValue;
  var unitWord;
  var ODIDWord = "OD";

  function isPrime(number) {
    var maxTest = parseInt(Math.sqrt(number) + 1);
    var primeNumber = true;
    for (let i = 2; i < maxTest; i++) {
      if (number % i === 0) {
        primeNumber = false;
        break;
      }
    }
    return primeNumber;
  }

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-planets").value = "";
    document.getElementById("input-ratio").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  function whitespace(value, columnMax) {
    let digits = Math.floor(Math.log10(value)) + 1;
    if(digits <1){digits = 1}
    let leadingSpaces = "";
    for(let i=0; i<(columnMax - digits);i++){
      leadingSpaces += " ";
    }
    return leadingSpaces
  }

  // Change the placeholder depending on the user's selection
  var z; // number of teeth, pinion
  var z2; //number of teeth, gear
  var m_n;
  var alpha_n_Degree;
  var alpha_n; //= parseFloat(alpha_n_Degree)*Math.PI/180;
  var precision;


  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    precision = document.getElementById("precision").value;
    var m_go = document.getElementById("input-ratio").value;

    logQA({
      page: "GCSplanetary",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      param7: unitWord,
    });

    var errorMessage = "Error";
    var okToCalc = true;


    if (!(numTeeth >= 6 && numTeeth <= 1000)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of teeth must be between 6 and 1000";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage = "Number of teeth must be an integer";
    }
    if (!(module >= 0.1 && module <= 300)) {
      okToCalc = false;
      errorMessage = errorMessage + "\nModule/DP must be between 0.1 and 300";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nPressure Angle must be between 1 and 45 degrees";
    }
    if (!(precision >= 0 && precision <= 8)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of Decimal Places must be between 0 and 8";
    }

    let z_g = numTeeth * (m_go -1);
    if(Math.abs(z_g - Math.round(z_g)) >= 0.01){
      okToCalc = false;
      errorMessage += "\nRing gear must be have an integer number of teeth: currently ";
      errorMessage += z_g.toFixed(3) + "\n";
      let altRatio = Math.round(z_g) / numTeeth + 1;
      errorMessage += "Consider target ratio of ";
      errorMessage += altRatio.toFixed(4) + "\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer();
    }
  }


  function involute(x) {
    //expects x in radians
    return Math.tan(x) - x;
  }

  function inverseInvolute(x) {
    //returns x in radians
    var maxError = 0.0000000000001;
    var high = Math.PI / 2;
    var low = 0;
    var guess = 0.1;
    var i = 0;
    var maxSteps = 100;

    while (Math.abs(involute(guess) - x) > maxError) {
      i = i + 1;
      if (involute(guess) > x) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }

      if (i >= maxSteps) {
        console.log("inverseInvolute failed to converge");
        throw new Error();
      }
    }
    return guess;
  }

  function calculateMOW() {
    let z_s = parseFloat(document.getElementById("input-numteeth").value);
    let targetRatio = parseFloat(document.getElementById("input-ratio").value);
    let targetPlanets = parseFloat(document.getElementById("input-planets").value);
    let m_n = parseFloat(document.getElementById("input-module").value);
    let alpha_n_Degree = parseFloat(document.getElementById("input-pangle").value);

    let alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    let precision = document.getElementById("precision").value;

    var m_nInput = m_n;
    let modWord;

    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    let unitConversion;
    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      unitWord = "inches";
      modWord = "Diametral Pitch";
      unitConversion = 25.4;
    } else {
      unitWord = "millimeters";
      modWord = "Module";
      unitConversion = 1;
    }

    let resultWarning = false;
    let resultWarningText = "";

    // Begin process of planetary gear design
    let z_g = z_s * (targetRatio -1);
    let planetCheck = (z_g + z_s) / targetPlanets;
    let recPlanets = 0;
    if(Math.abs(planetCheck - Math.round(planetCheck)) >= 0.01){
      resultWarning = true;
      resultWarningText += "Non-integer number of planets, currently ";
      resultWarningText += planetCheck.toFixed(3);
      for(let i=2; i<(z_g+z_s); i++){
        if(Math.abs(((z_g+z_s) / i) - Math.round((z_g+z_s) / i)) >= 0.01){
          recPlanets = i;
          break;
        }
      }
      resultWarningText += "\nConsider using " + recPlanets + " planets";
    }
    else {
      z_g = Math.round(z_g);
    }

    let z_p = (z_g -z_s)/2 -1; // number of teeth on the planet
    z_p = Math.floor(z_p);

    // calculate reference center distances
    let a_sp = (z_s+z_p) * m_n / 2; // Sun-Planet Center Distance
    let a_pa = (z_g-z_p) * m_n / 2; // Planet-Ring Center Distance

    let a_w = []; // working center distance
    for(let i=0; i<5; i++){
      let difference = a_pa - a_sp;
      let a_w_option = a_sp + difference * (i/4);
      let alpha_sp_option = Math.acos(a_sp*Math.cos(alpha_n)/a_w_option);
      let alpha_pa_option = Math.acos(a_pa*Math.cos(alpha_n)/a_w_option);
      a_w.push([a_w_option, alpha_sp_option, alpha_pa_option]);
    }

    let textToWrite;
    let primeTeeth = isPrime(z_s);

    let pitchDiaRing = z_g * m_n;

    var displayProjectName = getProjectNameFromID(project.project);
    var displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";

    

    textToWrite = `Inputs
Target Ratio:         \t${targetRatio}
Number of Teeth (Sun):\t${z_s}
Pressure Angle:       \t${alpha_n_Degree}\tdegrees
${modWord}: \t${m_nInput}

Outputs
${resultWarning ? "WARNING:\n" + resultWarningText : ''}
Number of Teeth (Sun): ${z_s}
Number of Teeth (Planets): ${z_p}
Number of Teeth (Ring Gear): ${z_g}
Number of Planets: ${targetPlanets}

Pitch Diameter (Ring): ${pitchDiaRing.toFixed(precision)}\tmm\t${(pitchDiaRing/25.4).toFixed(precision)}\tin
Minimum Center Distance: ${a_sp.toFixed(precision)}\tmm\t${(a_sp/25.4).toFixed(precision)}\tin
Maximum Center Distance: ${a_pa.toFixed(precision)}\tmm\t${(a_pa/25.4).toFixed(precision)}\tin

Center Distance Options:
  CD    |PA (S-P)|PA (P-A)
 (mm)    (deg)     (deg)
`;
for(let i=0; i<a_w.length; i++){
  textToWrite += whitespace(a_w[i][0], 4) + a_w[i][0].toFixed(precision) + "\t|\t" + whitespace(180/Math.PI * a_w[i][1], 3) + (180/Math.PI * a_w[i][1]).toFixed(precision) + "\t|\t" + whitespace(180/Math.PI * a_w[i][2], 3) + (180*Math.PI *a_w[i][2]).toFixed(precision) + "\n";
}
  textToWrite += `
\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">BETA: Planetary Gear Sets</div>
        <div>

          <span className="term-emphasis">This calculator is in beta and may not be reliable</span>
<br /><br />
        <p>
          A planetary gear set is a space-efficient way to distribute the load across one or more "Planet" gears
        </p>
        <p>
          Starting with the target ratio and an estimated tooth count for the "Sun" this calculator guides you through the initial
          design process for planetary gears
        </p>
        </div>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />
              <div hidden="true">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label className="unitslabel">Type</label>
                  <div>
                    <input
                      className="radioButton"
                      type="radio"
                      id="input-type-ext"
                      value="ext"
                      name="input-type"
                      defaultChecked
                    />
                    <label className="radioLabel" htmlFor="input-type-ext">
                      External
                    </label>
                  </div>
                  <div>
                    <input
                      className="radioButton"
                      type="radio"
                      id="input-type-int"
                      value="int"
                      name="input-type"
                    />
                    <label className="radioLabel" htmlFor="input-type-int">
                      Internal
                    </label>
                  </div>
                </div>
                </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth (Sun)<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-planets">
                  Target Number of Planets
                </label>
                <input
                  defaultValue={0}
                  className="inputbox"
                  type="number"
                  id="input-planets"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-ratio">
                  Target Ratio<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-ratio"
                  size="25"
                />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pangle">
                  Pressure Angle, normal (degrees)<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pangle"
                  size="25"
                />
              </div>

              <DecimalPlacesSelect defaultValue={2} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/planetary.png" fluid />
            <br />
            <p>A planetary set has a central "Sun" gear, orbited by multiple "Planet" gears, inside an internal "Ring" gear</p>
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          This calculator outputs a range of avaiable center distances between the Sun and Planet gears. Values are presented
          with corresponding working pressure angles for the Sun-Planet and Planet-Annular(Ring) gear pairs.
        </p>
      </Card>
    </>
  );
}
