import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import { resizeTextAnswer, logQA } from "./helpers";
import { ButtonBlockTextArea, SaveButtonBlock } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorGear3({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-ratio").value = "";
    document.getElementById("input-tolerance").value = "";
    document.getElementById("input-cgears").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'machine' for one set of change gears
    // Loading the dropdown should match this record type

    // Get project name
    saveInputs(event, "machine", project.project, false);
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    
    if(document.getElementById('input-gearname').value.length >0){
      document.getElementById('cglist').hidden = false; // show cg list selector
    }
    var ratio = parseFloat(document.getElementById("input-ratio").value);
    var tolerance = parseFloat(
      document.getElementById("input-tolerance").value
    );
    var changegears = document.getElementById("input-cgears").value;
    // strip spaces and whitespace from changegears
    changegears = changegears.replace(/\s/g, "");
    changegears = changegears.replace(".", ",");
    let changegearArray = changegears.split(",");

    logQA({ page: "GCSgear3", param5: ratio, param6: tolerance });

    var errorMessage = "";
    var okToCalc = true;

    // Check for invalid cases
    // TODO: add checks for change gears

    if (changegearArray.length <= 4 && changegearArray.length > 1) {
      okToCalc = false;
      errorMessage +=
        "More change gears required, or leave blank for defaults\n";
    }
    // Remove limit (could also limit based on level)
    // if (changegearArray.length > 80) {
    //   okToCalc = false;
    //   errorMessage +=
    //     "Exceeded max limit of number of 80 change gears, you provided " +
    //     changegearArray.length +
    //     "\n";
    // }

    if (changegearArray.length > 1) {
      for (let i = 0; i < changegearArray.length; i++) {
        if (
          parseFloat(changegearArray[i]) !== parseInt(changegearArray[i]) ||
          changegearArray[i] <= 0
        ) {
          // needs to be a single != because the type is changing from string to float
          okToCalc = false;
          errorMessage += "All change gears must be positive integers\n";
          break;
        }
      }
    }

    if (ratio <= 0 || isNaN(ratio)) {
      okToCalc = false;
      errorMessage += "Ratio must be positive\n";
    }
    if (tolerance < 0 || isNaN(tolerance)) {
      okToCalc = false;
      errorMessage += "Tolerance must be positive\n";
    }

    if (okToCalc) {
      calculateRatio();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer();
    }
  }

  function reqListener() {
    let displayText;

    if (this.status === 200) {
      let responseData = JSON.parse(this.responseText);

      if (responseData.length >= 1) {
        let outputData =
          "  a  \tb  \tc  \td  \t       ratio\t      distance\tA\tB\tC\n";
        for (let i = 0; i < responseData.length; i++) {
          outputData =
            outputData +
            whitespace(responseData[i][0]) +
            "\t" +
            whitespace(responseData[i][1]) +
            "\t" +
            whitespace(responseData[i][2]) +
            "\t" +
            whitespace(responseData[i][3]) +
            "\t" +
            responseData[i][4].toFixed(12) +
            "\t" +
            responseData[i][5].toFixed(12) +
            "\t" +
            whitespaceFlag(responseData[i][6]) +
            "\t" +
            whitespaceFlag(responseData[i][7]) +
            "\t" +
            whitespaceFlag(responseData[i][8]) +
            "\n";
        }
        displayText =
          "\n\nOutput -\t" + responseData.length + " solutions \n" + outputData;
      } else {
        displayText = "\n\nOutput\nNo solutions found";
      }
    } else {
      console.log("AWS FAILURE!");
      displayText = "\n\nERROR\nRequest failed. Error " + this.status;
    }

    setTextAreaText(displayText);
    resizeTextAnswer(displayText);
  }

  function reqFailure() {
    console.log("AWS FAILURE!");
    const displayText = "\n\nERROR\nRequest failed";
    setTextAreaText(displayText);
    resizeTextAnswer(displayText);
  }

  function calculateRatio() {
    var ratio = parseFloat(document.getElementById("input-ratio").value);
    var tolerance = parseFloat(
      document.getElementById("input-tolerance").value
    );
    var changegears = document.getElementById("input-cgears").value;
    // strip spaces and whitespace from changegears
    changegears = changegears.replace(/\s/g, "");
    changegears = changegears.replace(".", ",");
    let changegearArray = changegears.split(",");

    // Send request to AWS
    const req = new XMLHttpRequest();
    req.addEventListener("load", reqListener);
    req.addEventListener("error", reqFailure);
    //req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    req.open(
      "POST",
      "https://om3le2octfptsysa4zqreksfqa0pvgih.lambda-url.us-east-1.on.aws/"
    );

    req.send(
      JSON.stringify({
        ratio: ratio,
        tolerance: tolerance,
        cgears: changegearArray,
      })
    );

    if (tolerance >= 1) {
      // tolerance is number of zeros
      tolerance = Math.pow(10, -1 * (tolerance + 1));
    }

    const textboxText = `Input
Target Ratio:\t${ratio.toFixed(12)}
Tolerance:   \t${tolerance.toFixed(12)}`;

    let textToWrite = textboxText + "\n\nLoading...";

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  function whitespaceFlag(value) {
    if (value.length === 0) {
      return " ";
    } else {
      return value;
    }
  }

  function whitespace(value) {
    // Rough way to format values to be in neat columns
    if (value < 0) {
      return " " + value;
    }
    if (value < 10) {
      return "  " + value;
    } else if (value < 100) {
      return " " + value;
    } else {
      return value;
    }
  }

  function updateCGList(){

    // save the index change gears to the backup
    if(document.getElementById('input-cgears').value.length > 0 && document.getElementById('input-cgearsbackup').value.length == 0){
      document.getElementById('input-cgearsbackup').value = document.getElementById('input-cgears').value;
    }

    let cgList = document.getElementById('input-cglist').value;
    // console.log("CG List:" + cgList);
    if(cgList === "cgears"){
      document.getElementById('input-cgears').value = document.getElementById('input-cgearsbackup').value;
    }
    else {
    let cgears = document.getElementById('input-' + cgList).value;
    // console.log("Gears: " + cgears);
    // ref.current.contentWindow.postMessage(cgears, origin);
    document.getElementById('input-cgears').value = cgears;
    }
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">
          Change Gears Brute-Force (multi-solution)
        </div>
        <p>
          This calculator will output all possible change gear combinations,
          within a specified tolerance, using only the change gears provided (or
          its default set, 20-80).
        </p>
        <p>
          For most machines, the target ratio will be (# Teeth / Machine
          Constant), but this calculator will accept any arbitrary ratio.
          Tolerances can be given as +/- values, or as a "number of zeros" where
          '4' converts to 0.00001.
        </p>
        <p>
          Varying the tolerance can give more (or fewer) possible combinations,
          and all combinations (within the tolerance) are reported.
        </p>
        <p>
          This calculator may take some time (up to a minute!) to run, depending
          on the number of listed change gears. Changing the tolerance has no
          effect on runtime.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="machine"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              buttonText="Load Machine"
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
          {userLevel > 0 && (
            <div id="cglist" hidden={true} style={
              { marginTop: -10 + "px"
              }
              }>
            <label htmlFor="input-cglist">Select Change Gear List</label>
            <select
              className="inputbox"
              name="input-cglist"
              id="input-cglist"
              onChange={updateCGList}
            >
              <option value="cgears">Change gear list...</option>
              <option value="cgears">Index gears</option>
              <option value="cgearsfeed">Feed gears</option>
              <option value="cgearshelical">Differential gears</option>
            </select>
          </div>
          )}
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">Ratio</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-ratio"
                  size="25"
                  placeholder="Target Ratio"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Tolerance
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Either +/- value, or number of zeros (e.g. 4 = +/-
                      0.00001)
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-tolerance"
                  size="25"
                  placeholder="Tolerance"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Change Gears
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Comma-separated list of change gears
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-cgears"
                  size="25"
                  placeholder="(leave blank for default)"
                />
                <input
                  hidden={true}
                  className="inputbox"
                  type="text"
                  id="input-cgearsbackup"
                  size="25"
                  placeholder="Backup Index Change Gears"
                />
                <input
                  hidden={true}
                  className="inputbox"
                  type="text"
                  id="input-cgearsfeed"
                  size="25"
                  placeholder="List of Feed Change Gears"
                />
            <input
                  hidden={true}
                  className="inputbox"
                  type="text"
                  id="input-cgearshelical"
                  size="25"
                  placeholder="List of Differential Change Gears"
                />
                <br />
                <a
                  className="inputbox"
                  href="https://oilviscositychart.com/cgears/radio-input.php"
                >
                  Make a list
                </a>
              </div>

              <div>Click once and wait, may take up to 60 seconds!</div>
              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
              {userLevel > 0 && (
                <div hidden = {true}>
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                />
                </div>
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/cgdb-1.png" fluid /> <br />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        Flags are:
        <ul>
          <li>A: Exact match, distance &lt; 10^-12</li>
          <li>B: b &gt;= c+d, collision risk with gear B</li>
          <li>C: c &gt;= a+b, collision risk with gear C</li>
        </ul>
          <h3>The Math of Change Gear Calculation</h3>
          <p>
            From a math standpoint, the selection of change gears is a problem
            of continued fractions, and conjugate fraction methods. In reality
            there are many more boundary conditions, such as: the machine size,
            limits on the "Scissor", and which change gears the operator
            actually possesses. There are a surprising number of possible
            solutions ranging above 12 million, and they require computer time
            to solve. The boundary conditions and other optimizations can make
            this compute time much smaller and these techniques are used in our
            calculators.
          </p>
        
      </Card>
    </>
  );
}
